
import { Component, Mixins, Prop, Emit, Watch, Ref } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { HasProperyPart } from "@/mixins/has-property-part";

@Component({
    components: {
        InputRadio: () => import("@/components/forms/property-radio.vue"),
        ValidationProvider,
    },
})
export default class PropertyPartBuildingPlan extends Mixins(HasProperyPart) {
    @Prop({ default: null }) value!: boolean | null;

    localValue: boolean | null = null;

    @Emit("input")
    handleInput(v: boolean | null) {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: boolean | null) {
        this.localValue = newValue;
    }
}
